function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {

        $('.set-filters').click(function (e) {
            $('.filters-mobile').removeClass('visible');
            searchJobs();
        });

        $('.clear-filters').click(function (e) {
            emptyFilters();
        });

        $('.mobile-industry input').click(function () {
            var elem = $('.industry-filter input[value="' + $(this).val() + '"]')
            if (elem.prop('checked') === true) {
                elem.prop('checked', false)
            } else {
                elem.prop('checked', true)
            }
        })

        $('.mobile-duration input').click(function () {
            var elem = $('.duration-filter input[value="' + $(this).val() + '"]')
            if (elem.prop('checked') === true) {
                elem.prop('checked', false)
            } else {
                elem.prop('checked', true)
            }
        })

        $('.mobile-time-type input').click(function () {
            var elem = $('.time-type-filter input[value="' + $(this).val() + '"]')
            if (elem.prop('checked') === true) {
                elem.prop('checked', false)
            } else {
                elem.prop('checked', true)
            }
        })

        $('.industry-filter input').click(function () {
            var elem = $('.mobile-industry input[value="' + $(this).val() + '"]')
            if (elem.prop('checked') === true) {
                elem.prop('checked', false)
            } else {
                elem.prop('checked', true)
            }
        })

        $('.duration-filter input').click(function () {
            var elem = $('.mobile-duration input[value="' + $(this).val() + '"]')
            if (elem.prop('checked') === true) {
                elem.prop('checked', false)
            } else {
                elem.prop('checked', true)
            }
        })

        $('.time-type-filter input').click(function () {
            var elem = $('.mobile-time-type input[value="' + $(this).val() + '"]')
            if (elem.prop('checked') === true) {
                elem.prop('checked', false)
            } else {
                elem.prop('checked', true)
            }
        })

        $('.filters-button').click(function (e) {
            e.preventDefault();
            $('.filters-mobile').addClass('visible');
        });

        $('.close-filter-window').click(function () {
            $('.filters-mobile').removeClass('visible');
        })

        function emptyFilters() {
            $('input[type=checkbox]').prop('checked', false);
        }

        $('.sort-button').click(function (e) {
            if ($(this).hasClass('sort-button-clicked')) {
                $(this).removeClass('sort-button-clicked');
                $('.sort-options').removeClass('sort-options-visible');
            } else {
                $(this).addClass('sort-button-clicked');
                $('.sort-options').addClass('sort-options-visible');
            }
        });

        $('.sort-options').children().click(function (e) {
            e.preventDefault();
            $('.sort-button').removeClass('sort-button-clicked');
            $('.sort-options').removeClass('sort-options-visible');
        });

        $('.sort-option').click(function (e) {
            if ($(this).hasClass('sort-option-selected')) {
                $(this).removeClass('sort-option-selected');
            } else {
                $(this).addClass('sort-option-selected');
            }
            $('.sort-option').not($(this)).removeClass('sort-option-selected');
        });

        $('.job-listing-hero form').submit(function (e) {
            e.preventDefault();
            searchJobs();
        })

        $('.industry-filter input, .duration-filter input, .time-type-filter input, .sort-options .sort-option').click(function () {
            searchJobs();
        })

        function searchJobs() {
            var searchValue = $('.job-search-word-input').val();
            var locations = [];
            $('.job-search-select-locations input:checkbox:checked').each(function () {
                locations.push($(this).val())
            })
            var industry = []
            $('.industry-filter input:checkbox:checked').each(function () {
                industry.push($(this).val())
            })
            var duration = []
            $('.duration-filter input:checkbox:checked').each(function () {
                duration.push($(this).val())
            })
            var timeType = []
            $('.time-type-filter input:checkbox:checked').each(function () {
                timeType.push($(this).val())
            })

            var order = '';
            if ($('.job-list-sort .sort-option-selected').length) {
                order = $('.job-list-sort .sort-option-selected').data('value')
            }

            $.get(wpcf7.api.root + "jobs/v1/filter", {
                search: searchValue,
                industry: industry,
                location: locations,
                duration: duration,
                time_type: timeType,
                order: order,
            }, function (data) {
                $('.job-count').html(data.count)
                $('.job-results').html(data.data)
            });
        }
    })

})(jQuery);
